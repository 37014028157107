import { ActivityTeaserFragmentFragment } from '../../../generated/types';
import React from 'react';
import { TeaserProps } from '../../../types/global-types';
import { Box } from '@chakra-ui/react';
import { useStore } from '../../../store/provider';
import getIconForCategoryKey from '../../../utils/getIconForCategoryKey';
import EbikeAtlasTeaser from '../../Common/EbikeAtlasTeaser';

type EbikeAtlasActivityTeaserProps = TeaserProps & {
  data: ActivityTeaserFragmentFragment;
};

const EbikeAtlasActivityTeaser: React.FC<EbikeAtlasActivityTeaserProps> = ({
  data,
  showCategory,
  ...rest
}) => {
  const store = useStore();

  return (
    <Box key={data.id} width="100%">
      <EbikeAtlasTeaser
        type="activity"
        text={data.body}
        image={data.teaserImage?.teaserLarge?.url}
        imageAlt={data.teaserImage?.imageAlt}
        imageTitle={data.teaserImage?.imageTitle}
        categoryKey={data.category?.key}
        title={data.title}
        region={data.region?.name}
        country={data.country?.name}
        url={data.url}
        {...rest}
      />
    </Box>
  );
};

export default EbikeAtlasActivityTeaser;
